.container {
    width: 100%;
    max-width: 1640px;
    min-width: 924px;
    display: flex;
    margin-top: 62px;
    gap: 50px;
    flex-wrap: wrap;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 924px;
    margin-bottom: 40px;
    width: 812px;
    &__label {
        margin-bottom: 16px;
        font: 'Jost', sans-serif;
        font-size: 25px;

    }

    &__input {
        margin-bottom: 38px;
        width: 100%;
        font: 'Jost', sans-serif;
        font-size: 25px;
    }

    &__textarea {
        height: 240px;
        width: 100%;
        font: 'Jost', sans-serif;
        font-size: 25px;
        resize: none;
    }

    & button:last-of-type {
        margin-top: 40px;
        align-self: flex-end;
    }
}

.contactDetails {
    margin-top: 60px;
    display: grid;
    flex-direction: column;
    grid-template-columns: 40px 400px;
    grid-template-rows: repeat(6, 45px);
    align-items: center;
    justify-items: center;
    gap: 70px;

    &__single {
        display: flex;
        justify-self: left;
        gap: 60px;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        text-decoration: none;
        color: black;
        font: 'Jost', sans-serif;
        font-size: 30px;
        text-align: left;

        &:hover {
            background: rgba(255, 0, 0, 0.3);
            border-radius: 10px;
        }

        &:active {
            color: white;
            background: rgb(255, 0, 0);
        }

        &Image {
            height: 36px;
        }
    }
}

.message {
    &__cleared {
        font: 'Jost', sans-serif;
        margin-bottom: 10px;
        font-size: 22px;
        color: rgb(18, 206, 125);
    }

    &__failed {
        font: 'Jost', sans-serif;
        margin-bottom: 10px;
        font-size: 22px;
        color: rgb(223, 73, 73);
    }
}

@media screen and (max-width: 1023px) {
    .container {
        width: 100%;
        min-width: 260px;
        max-width: 1023px;
        margin-top: 0px;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .form {
        min-width: 260px;
        max-width: 924px;
        margin-bottom: 10px;
        width: calc(100vw - 100px);

        &__label {
            font-size: 16px;
            margin-bottom: 8px;
        }
        &__input{
            width: 100%;
            margin-bottom: 10px;
        }

        &__textarea {
            width: 100%;
        }

        & button:last-of-type {
            align-self: flex-end;
            margin-top: 10px;
        }
    }

    .message {
        &__cleared {
            font: 'Jost', sans-serif;
            margin-bottom: 6px;
            font-size: 16px;
            color: rgb(18, 206, 125);
        }

        &__failed {
            font: 'Jost', sans-serif;
            margin-bottom: 6px;
            font-size: 16px;
            color: rgb(223, 73, 73);
        }
    }

    .contactDetails {
        gap: 10px;
        margin-top: 5px;
        align-self: center;
        grid-template-columns: 40px auto;
        grid-template-rows: repeat(3, 45px);
        margin-bottom: 50px;
        &__single {
            display: flex;
            justify-self: left;
            gap: 60px;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
            text-decoration: none;
            color: black;
            font: 'Jost', sans-serif;
            font-size: 16px;
            text-align: left;
            &:hover {
                background: rgba(255, 0, 0, 0.3);
                border-radius: 10px;
            }
            &:active {
                color: white;
                background: rgb(255, 0, 0);
            }
            &Image {
                height: 24px;
            }
        }
    }
}