.container {
    margin-bottom: 120px;
}
.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__grid {
        width: 100%;
        display: grid;
        row-gap: 80px;
        column-gap: 12%;
        row-gap: 100px;
        grid-template-columns: repeat(2, 44%);
        margin: 120px 0 0 0;
        align-items: center;
        justify-items: center;
        margin-bottom: 100px;
    }
    &__name {
        font: 'Jost', sans-serif;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 22px;
    }
    &__divider {
        width: 394px;
        height: 3px;
        background: #ff0000;
        display: block;
        margin-bottom: 48px;
    }
    &__description {
        text-align: left;
        font: 'Segoe UI', sans-serif;
        font-size: 22px;
    }
}
@media screen and (max-width: 1023px){
    .container{
        margin-bottom: 0;
    }
    .service{
        &__grid{
            display: inline-block;
            margin-bottom: 0;
            margin: 30px 0 0 0;
        }
        &__divider{
            width: 125px;
            margin-bottom: 24px;
        }
        &__name{
            font-size: 22px;
            font-weight: normal;
            margin-top: 30px;
        }&__description{
            font-size: 16px;
            margin-bottom: 30px;
            text-align: center;
        }
    }
}