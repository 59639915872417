.container {
    width: 100vw;
    margin-left: calc(-1 * (100vw - 100%)/2);
    display: flex;
    background: #F6F6F6;
    padding-top: 40px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer {
    min-width: 1024px;
    width: 100%;
    padding: 0 50px;
    max-width: 1640px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &__logo {
        height: 54px;
    }
    &__contact{
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 16px;
        column-gap: 26px;
        align-items: center;
        justify-items: center;
        &Title {
            grid-column: 1/3;
            justify-self: left;
        }
        &Detail {
            text-decoration: none;
            color: black;
            justify-self: left;
            text-align: left;
        }
        &Image {
            height: 20px;
        }
    }
    &__siteMap{
        display: grid;
        grid-template-columns: auto;
        row-gap: 16px;
        column-gap: 26px;
        align-items: center;
        justify-items: left;
        &Title {
            justify-self: left;
        }
        &Detail {
            text-decoration: none;
            color: black;
            justify-self: left;
            text-align: left;
        }
        &Image {
            height: 20px;
        }
    }
    &__separator {
        flex-grow: 1;
    }
    &__backButton {
        min-width: 150px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        color: black;
        &:hover {
            background: rgba(255,0,0,0.3);
        }
        &:active {
            background: rgb(255,0,0);
            color: white;
        }
        &Image {
            width: 26px;
        }
        &Text {
            font: 'Jost', sans-serif;
            font-size: 25px;
            text-decoration: none;
            color: inherit;
        }
    }
}
@media screen and (max-width: 1023px){
    .container{
        margin-left: 0;
    }
    .Footer{
        padding-bottom: 30px;
        margin-top: 30px;
        min-width: 260px;
        max-width: 1023px;
        &__logo{
            height: 40px;
        }
        &__siteMap{
            display: none;
        }
        &__contact{
            display: none;
        }
        &__backButton{
            gap: 10px;
            &Text{
                font-size: 18px;
            }
        }
    }
}

