.wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
}

.container {
    padding: 0 calc((100vw - 1640px)/2);
    width: 100%;
    min-width: 1024px;
    max-width: 1640px;
    min-height: calc(100vh - 120px);
}

.title {
    margin: 180px 0 30px 0;
    font: 'Jost', sans-serif;
    font-size: 35px;
    font-weight: bold;
}
.subtitle {
    margin: 50px 0 0 0;
    font: 'Jost', sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.description {
    margin: 10px 0 0 0;
    font: 'Segoe UI', sans-serif;
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 50px;
}

.grid {
    display: grid;
    grid-template-columns: 20px 275px;
    grid-template-rows: repeat(4, 20px);
    row-gap: 16px;
    column-gap: 25px;
    align-items: center;
    justify-items: center;
    margin-bottom: 50px;
    &__image {
        height: 20px;
    }
    &__text {
        justify-self: left;
        font: 'Jost', sans-serif;
        font-size: 20px;
    }
}
@media screen and(max-width: 1023px){
    .container{
        width: 100%;
        min-width: 360px;
        min-height: calc(100vh - 170px);
        padding: 0 50px;
    }
    .title{
        margin: 60px 0 20px 0;
        font-size: 18px;
    }
    .grid{
        grid-template-columns: 20px auto;
    }
    .description{
        font-size: 16px;
    }
    .subtitle{
        font-size: 20px;
    }
}