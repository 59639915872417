* {
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}

::selection{
  color:white;
  background-color: red;
}

::-moz-selection{
  color:white;
  background-color: red;
}

.gridWrapper{
  display:grid;
  grid-template-rows: 120px auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
}
.gridWrapper.animated{
  grid-template-rows: 80px auto;
}

body {
  overflow: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
