.wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.secondWrapper {
    height: 100%;
    width: 100%;
    min-width: 924px;
    max-width: 1640px;
    display: flex;
    flex-direction: column;
}
.container {
    max-width: 1640px;
    padding: 0 50px;
    min-width: 1024px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 120px);
}
.separator {
    flex-grow: 1;
}

.more {
    display: flex;
    padding: 20px;
    transform: rotate(180deg);
    border-radius: 10px;
    &:hover {
        background: rgba(255,0,0,0.3);
        cursor: pointer;
    }
}
.home {
    display: flex;
    min-width: 1024px;
    padding: 0 50px;
    max-width: 1640px;
    width: 100%;
    align-items: center;
    &__button {
        margin-top: 55px;
    }
    &__text {
        font: 'Jost', sans-serif;
        font-size: clamp(40px, 5vw, 60px);
        font-weight: bold;
        text-align: left;
    }
    &__logo {
        width: 50%;
    }
    &__separator {
        flex-grow: 1;
    }
}
@media screen and (max-width: 1023px){
    .secondWrapper{
        min-width: 360px;
        max-width: 1023px;
    }
    .container{
        min-width: 360px;
        max-width: 1023px;
    }
    .home{
        min-width: 370px;
        max-width: 1023px;
        flex-direction: column;
        &__logo{
            width: 100%;
            order: 0;
        }
        &__text{
            font-size: clamp(26px,5vw,40px);
            font-weight: normal;
            text-align: center;
            order:1;
        }
        &__button{
            order:2;
        }
        &__separator{
            flex-grow: 0;
        }
    }
}