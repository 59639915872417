.container {
    max-width: calc(100vw - 100px);
    width: 1640px;
    min-width: 924px;
    margin: 120px 0 0 0;
    display: grid;
    justify-items: center;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 462px);
}

.team {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;

    &__image {
        justify-self: center;
        align-self: center;
        display: block;
        background: black;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 30px;
        position: relative;

        &:hover {
            transition: all 0.6s ease;

            &::before {
                transition: all 0.6s ease;
                margin: -10px 0 0 -10px;
                height: 220px;
                width: 220px;
            }
        }

        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            background: rgba(255, 0, 0, 0.3);
            height: 200px;
            width: 200px;
            border-radius: 50%;
            margin: random(5) + 5px 0 0 random(5)+5px;
        }
    }

    &__name {
        font: 'Jost', sans-serif;
        font-size: 30px;
        color: #121212;
    }

    &__divider {
        display: block;
        width: 140px;
        height: 2px;
        background: #FF0000;
        margin-bottom: 28px;
    }

    &__position {
        font: 'Jost', sans-serif;
        font-size: 25px;
        color: #121212;
        margin-bottom: 25px;
    }

    &__description {
        font: 'Segoe UI', sans-serif;
        font-size: 22px;
        color: #707070;
        word-wrap: break-word;
        word-break: break-all;
    }
}

@media screen and(max-width:1023px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: calc(100vw - 100px);
        width: 100%;
        min-width: 220px;
        margin: 30px 0 0 0;
    }

    .team {
        width: 100%;
        min-width: 220px;
        &__image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 30px;
            position: relative;

            &:hover {
                transition: all 0.6s ease;

                &::before {
                    transition: all 0.6s ease;
                    margin: -10px 0 0 -10px;
                    height: 110px;
                    width: 110px;
                }
            }

            &::before {
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                background: rgba(255, 0, 0, 0.3);
                height: 100px;
                width: 100px;
                border-radius: 50%;
                margin: random(5) + 5px 0 0 random(5)+5px;
            }
        }
        &__name {
            font: 'Jost', sans-serif;
            font-size: 22px;
            color: #121212;
            margin-bottom: 3px;
        }
    
        &__divider {
            display: block;
            width: 140px;
            height: 2px;
            background: #FF0000;
            margin-bottom: 6px;
        }
    
        &__position {
            font: 'Jost', sans-serif;
            font-size: 16px;
            color: #121212;
            margin-bottom: 16px;
        }
    
        &__description {
            font: 'Segoe UI', sans-serif;
            font-size: 16px;
            color: #707070;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}