.container {
    width: 100vw;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    padding-top: 38px;
    padding-bottom: 20px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: all 0.5s ease;
    &.animated {
        transition: all 0.5s ease;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.Topbar {
    min-width: 1024px;
    width: 1640px;
    max-width: 1640px;
    padding: 0 50px;
    display: flex;
    gap: 40px;
    &__image {
        height: 62px;
    }
    &__separator {
        width: 100%;
    }
    &__link {
        font: 'Jost', sans-serif;
        font-size: 22px;
        text-decoration: none;
        color: black;
        align-self: flex-end;
        white-space: nowrap;
        &:hover {
            color: rgb(255,0,0);
        }
    }
}

.hamburger {
    display: none;
    &Menu {
        display: none;
    }
}
@media screen and (max-width: 1023px){
    .Topbar{
        display: flex;
        align-items: center;
        min-width: 360px;
        width: 1023px;
        max-width: 1023px;
        transition: all 0.5s ease-in-out;
        justify-content: center;
        gap: 0;
        grid-gap: 0;
        position: relative;
        &.animated {
            position: absolute;
            top: 0;
        }
        &__separator, &__image {
            transition: all 0.5s ease-in-out;
            visibility: visible;
            opacity: 1;
            &.animated {
                transition: all 0.5s ease-in-out;
                visibility: hidden;
                opacity: 0;
                width: 0%;
            }
        }
        &__separator {
            width: 100%;
        }
        &__image {
            height: 62px;
        }
        &__link {
            display: none;
        }
    }
    .hamburger {
        display: flex;
        flex-direction: column;
        //gap: 4px;
        opacity: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &Menu {
            flex-direction: column;
            background: red;
            padding-top: calc(100vh / 6);
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 999;
            align-items: center;
            justify-content: center;
            display: flex;
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease-in-out;
            &.animated {
                visibility: visible;
                transition: all 0.5s ease-in-out;
                opacity: 1;
            }
            &__link {
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100vh / 6);
                font-size: 30px;
                width: 100%;
            }
        }
        &__line {
            visibility: visible;
            display: block;
            width: 30px;
            height: 4px;
            margin-bottom: 4px;
            background: black;
            transition: all 0.5s ease-in-out;
            z-index: 9999;
            opacity: 1;
        }
        &.animated {
            height: calc(100vh / 6);
            & div:first-child {
                transform: translateY(8px) rotate(45deg) ;
                background: white;
            }
            & div:last-child {
                transform: translateY(-8px) rotate(-45deg);
                background: white;
            }
            & div:nth-child(2) {
                opacity: 0;
            }
        }
    }
}