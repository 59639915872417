.Topbar {
    min-width: 260px;
    width: 100%;
    max-width: 1023px;
    padding: 0 50px;
    display: flex;
    gap: 20px;
    &__separator {
        flex-grow: 1;
    }
    &__link {
        font: 'Jost', sans-serif;
        font-size: 22px;
        text-decoration: none;
        color: black;
        align-self: flex-end;
        &:hover {
            color: rgb(255,0,0);
        }
    }
}