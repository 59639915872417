.container {
    width: 100%;
    display: grid;
    row-gap: 80px;
    column-gap: 220px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 120px;
    align-items: end;
}
.career {
    width: 350px;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    padding: 10px;
    border-radius: 10px;
    &:hover {
        background: rgba(0,0,0,0.2);
    }
    &:active {
        background: rgba(0,0,0,0.5);
    }
    &__title {
        font: 'Jost', sans-serif;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 16px;
        max-width: 250px;
    }
    &__divider {
        width: 100%;
        background: #ff0000;
        height: 2px;
        display: block;
        margin-bottom: 20px;
    }
    &__grid {
        display: grid;
        grid-template-columns: 20px 275px;
        grid-template-rows: repeat(4, 20px);
        row-gap: 16px;
        column-gap: 25px;
        align-items: center;
        justify-items: center;
        &Image {
            height: 20px;
        }
        &Text {
            justify-self: left;
            font: 'Jost', sans-serif;
            font-size: 20px;
        }
    }
}
@media screen and (max-width:1023px){
    .container{
        display: flex;
        flex-direction: column;
        align-items:f;
        row-gap: 15px;
        margin-top: 15px;
        align-items: center;
    }
    .career{
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        &__grid {
            display: grid;
            grid-template-columns: 20px 235px;
            grid-template-rows: repeat(4, 20px);
            row-gap: 12px;
            column-gap: 25px;
            justify-items: center;
            width: 260px;
            &Image {
                height: 20px;
            }
            &Text {
                justify-self: left;
                font: 'Jost', sans-serif;
                font-size: 18px;
            }
        }
        &__divider {
            width: 60vw;
            max-width: 260px;
            background: #ff0000;
            height: 2px;
            display: block;
            margin-bottom: 20px;
        }
        &__title {
            font: 'Jost', sans-serif;
            font-size: 18px;
            font-weight: normal;
            margin-bottom: 16px;
            max-width: 250px;
        }
    }
}