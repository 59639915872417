.container {
    display: flex;
    width: 812px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 380px;
    & div:last-child(1){
        margin-bottom: 64px;
    }
}
.inputfield {
    width: 812px;
    height: 45px;
    border: solid 1px #121212;
    margin-bottom: 36px;
}
.inputlabel {
    font: 'Jost',sans-serif;
    font-size: 22px;
    margin-bottom: 8px;
    color: #121212;
    &--disabled{
        font: 'Jost',sans-serif;
        font-size: 22px;
        color: #6b6a6a;
    }
}
.filecontainer{
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
.filefield{
    display: block;
    background-color: #dfdbdb;
    color: #6b6a6a;
    margin-right: 16px;
    padding: 4px;
    width: 674px;
}
.buttonfile{
    color: #121212;
    font: 'Segoe UI',sans-serif;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin: 16px 0 32px 0;
}
.consenttext{
    font: 'Jost',sans-serif;
    size: 22px;
    color: #121212;
    margin-left: 16px;
}
.message {
    &__ok {
        font: 'Jost',sans-serif;
        margin-top: 10px;
        font-size: 22px;
        color: rgb(18,206,125);
    }
    &__error {
        font: 'Jost',sans-serif;
        margin-top: 10px;
        font-size: 22px;
        color: rgb(223,73,73);
    }
}
.dragAndDrop {
    height: 100px;
    width: 812px;
    margin-bottom: 32px;
}
@media screen and(max-width: 1023px){
    .container{
        min-width: 360px;
        width: 100%;
        max-width: 924px;
        margin-bottom: 50px;
    }
    .inputfield{
        min-width: 360px;
        width: 100%;
        max-width: 924px;
        height: 35px;
        margin-bottom: 12px;
    }
    .inputlabel{
        font-size: 16px;
        &--disabled{
            font-size: 18px;
        }
    }
    .dragAndDrop{
        min-width: 360px;
        width: 100%;
    }
    .message{
        &__ok{
            font-size: 16px;
        }
        &__error{
            font-size: 16px;
        }
    }
    .consenttext{
        font-size: 14px;
    }
}