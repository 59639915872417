.wrapper {
    width: 100%;
    min-width: 1024px;
    max-width: 1640px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0 50px;
    &__title {
        font: 'Jost', sans-serif;
        font-size: 30px;
        color: #121212;
    }
    &__subtitle {
        font: 'Jost', sans-serif;
        font-weight: bold;
        font-size: 45px;
        color: #121212;
        margin: 30px 0 0 0;
    }
    &__description {
        margin: 34px 0 0 0;
        max-width: 900px;
        text-align: left;
    }
}

.container {
    width: 100%;
    display: grid;
    row-gap: 80px;
    column-gap: 220px;
    grid-template-columns: repeat(3, 1fr);
}
@media screen and(max-width: 1023px){
    .wrapper{
        min-width: 360px;
        max-width: 1023px;
        &__title{
            font-size: 16px;
        }
        &__subtitle{
            font-weight: normal;
            font-size: 18px;
            margin: 15px 0 0 0;
        }
    }
}