.button {
    padding: 14px 34px;
    min-width: 160px;
    border-radius: 20px;
    background: #ff0000;
    color: white;
    border: none;
    font: 'Jost', sans-serif;
    font-size: 25px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &:hover {
        cursor: pointer;
    }

    &:active {
        cursor: pointer;
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &Inactive {
        padding: 14px 34px;
        min-width: 160px;
        border-radius: 20px;
        background: grey;
        color: white;
        border: none;
        font: 'Jost', sans-serif;
        font-size: 25px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        &:hover {
            cursor: pointer;
        }

        &:active {
            cursor: pointer;
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }
}

@media screen and (max-width: 1023px) {
    .button {
        padding: 12px 16px;
        font-size: 16px;
        margin-top: 15px;

        &Inactive {
            padding: 12px 16px;
            font-size: 14px;
            min-width: 80px;
        }
    }
}